.bottom-tab-nav {
    border-top: solid 1px #EBEBEB;
    z-index: 1000
}

.bottom-nav-link.active {
    color: #4469ef;
}

.h-xl {
    height: 32rem
}

.leaflet-div-icon {
    background: transparent !important;
    border: none !important;
}

@media (max-width: 640px) {
    .max-w-xxs {
        max-width: 8rem;
    }
}

@media (min-width: 992px) {

    .DayPicker-Month {
        width: 42rem;
        height: 30rem;
    }

}